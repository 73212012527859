/* saca el reCAPTCHA */
.grecaptcha-badge {
  visibility: hidden;
}

.card {
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.21);
}

.amarilloFondo {
  background-color: #fdcb6e;
}

.amarillo {
  color: #fdcb6e;
}

body,
html {
  background-image: url("assets/img/50-Beautiful-and-Minimalist-Presentation-Backgrounds-03.jpg");
  background-size: cover !important;
  background-repeat: no-repeat;
  margin: 0;
  /* font-family: Roboto, "Helvetica Neue", sans-serif; */
  overflow-x: hidden;
  height: 100%;
  /* background: rgb(119,119,119); */
  /* background: linear-gradient(90deg, rgba(119,119,119,1) 0%, rgba(120,120,120,1) 29%, rgba(221,221,221,1) 100%); */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .paddingCard {
    padding: 10px 90px 00px 90px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .paddingCard {
    padding: 10px 90px 00px 90px;
  }
}

.link {
  cursor: pointer;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

html,
body {
  height: 100%;
}

.fondo-modal {
  background-color: rgba(0, 0, 0, 0.4);
  left: 0px;
  height: 100%;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9999;
}

.tituloModal {
  color: gray;
  font-size: 14px;
  margin-bottom: 5px;
}

.fa-trash {
  margin: 1.75px !important;
}

table > thead > tr {
  font-size: 12px;
  color: gray;
}

table > tbody > tr {
  font-size: 15px;
}

.page-item.active .page-link {
  background-color: gray;
  border-color: gray;
}

.page-link {
  color: gray;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  color: gray;
}

.btn-secondary:hover {
  transition: all 0.2s;
  box-shadow: 1px 1px 5px 3px #6c757d;
}

.btn-outline-secondary:hover {
  transition: all 0.2s;
  box-shadow: 1px 1px 5px 3px #6c757d;
}

.ngx-pagination .current {
  background-color: #6c757d !important;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 4px 1px #6c757d;
}

.nav-link {
  color: #6c757d;
}
.nav-link:hover {
  color: #474c52;
  text-shadow: 1px 1px 4px #adadada4;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.07);
}

.card-header h4 {
  text-shadow: 1px 1px 2px grey;
}
table td {
  text-transform: uppercase !important;
}
